import { FormProvider, useForm } from 'react-hook-form'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import LoadingPage from '../../LoadingPage'
import { WinnerT } from '../../../types/winner'
import { useGetWinnerQuery } from '../../../store/winners'
import WinnerForm from './WinnerForm'

const UpdateWinner = () => {
  const { winnerId } = useParams<{ winnerId: string }>()

  if (!winnerId) {
    throw new Error('Winner ID not provided.')
  }

  const formContext = useForm<WinnerT>()
  const { data: winner } = useGetWinnerQuery(winnerId)

  if (winner) {
    Object.keys(winner).forEach((untypedWinnerKey) => {
      const winnerKey = untypedWinnerKey as keyof WinnerT
      formContext.setValue(winnerKey, winner[winnerKey])
    })
  }

  return (
    <Container>
      <FormProvider {...formContext}>
        <WinnerForm />
      </FormProvider>
      
    </Container>
  )
}

export default UpdateWinner
