/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { useGetAllWinnersQuery, useUpdateWinnerMutation } from '../../../store/winners';
import { useAppSelector } from '../../../store/hooks';
import Layout from '../../page/Layout';
import { Button, CardMedia, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL';
import { Link } from 'react-router-dom';
import MarkdownView from '../../slides/slide-view/MarkdownView';
import { css } from '@emotion/react'

const WinnersListPage = () => {
  const { data: winners } = useGetAllWinnersQuery();
  const [updateWinner] = useUpdateWinnerMutation();
  const userMe = useAppSelector((state) => state.auth.userMe);

  const handleUpdateIndex = async (id: string, newIndex: number) => {
    try {
      await updateWinner({ id, index: Number(newIndex) });
    } catch (error) {
      console.error('Failed to update index:', error);
    }
  };

  const [indexValues, setIndexValues] = useState<{ [key: string]: number | undefined }>({});

  // ---sort----------------------------------------------------------------------------
  const sortedWinners = winners
    ? [...winners].sort((a, b) => (b.index ?? 0) - (a.index ?? 0))
    : [];

  const handleIndexChange = (id: string, value: string) => {
    setIndexValues((prev) => ({
      ...prev,
      [id]: Number(value),
    }));
  };

  const handleBlurOrEnter = (id: string, currentIndex: number | undefined) => {
    if (indexValues[id] !== currentIndex) {
      handleUpdateIndex(id, indexValues[id] ?? 0);
    }
  };
  // ----------------------------------------------------------------------------sort---

  // ----------------------------------------------------------------------------------------------
  const renderCardMedia = (isImageFile: { fileHash: string } | undefined, width = '100%', height = '100%') => {
    return isImageFile ? (
      <CardMedia
        component="img"
        style={{
          width,
          height,
          borderRadius: 12,
          objectFit: 'contain',
        }}
        image={`${FILES_BASE_URL}/${isImageFile.fileHash}`}
      />
    ) : null;
  };

  // ---isDifferentGroupe----------------------------------------------------------
  let previousGroupNumber: string | null = null;
  // ----------------------------------------------------------isDifferentGroupe---



  // ---return-------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  return (
    <Layout>
      <Container maxWidth="md" dir="rtl">
        <div style={{ width: '100%', height: 30 }} />

        <h2 style={{ textAlign: 'center' }}>أسماء الفائزين بمسابقات فوتون التعليمية</h2>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">اسم الفائز</TableCell>
                <TableCell align="center">عنوان المسابقة</TableCell>
                <TableCell align="center">المدرسة/المدينة</TableCell>
                <TableCell align="center">تحت رعاية</TableCell>
                {userMe?.role === 'admin' && (
                  <TableCell align="center">AdminControl</TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody css={mCss.TableBody}>
              {sortedWinners?.map((winner, idx) => {
                
                
                const isDifferentGroupNumber = previousGroupNumber !== winner.groupNumber;
                previousGroupNumber = winner.groupNumber ?? null;
                return (
                  <>
                    {isDifferentGroupNumber && (<h3 style={{backgroundColor: 'MediumSeaGreen', color: 'whitesmoke', width: '200%' }}>{winner.groupNumber}</h3>) }


                    <TableRow
                      key={winner.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        backgroundColor: idx % 2 === 0 ? 'beige' : 'gray.200',
                      }}
                    >

                      <TableCell align="center" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      >
                        {renderCardMedia(winner.winnerImageFile, '5rem', '5rem')}
                        {winner.winnerName}
                      </TableCell>

                      <TableCell align="center"><MarkdownView value={winner.competitionName ?? ''} /></TableCell>
                      <TableCell align="center">{winner.address}</TableCell>

                      <TableCell align="center" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {winner.sponsorLink && (
                          <Button
                            component={Link}
                            to={winner.sponsorLink}
                            target='_blank'
                          >
                            {renderCardMedia(winner.sponsorImageFile, '5rem', '5rem')}
                            {winner.sponsorName}
                          </Button>
                        )}
                      </TableCell>


                      {/* <TableCell align="center">{winner.note}</TableCell> */}
                      {/* <TableCell align="center">{winner.sponsorDescription}</TableCell> */}
                      {/* <TableCell align="center">{winner.date}</TableCell> */}
                      {/* <TableCell align="center">{winner.status}</TableCell> */}
                      {/* {renderCardMedia(winner.competitionImageFile, '100%', '60%')} */}

                      {userMe?.role === 'admin' && (
                        <TableCell align="center">
                          {winner.index}

                          <TextField
                            label="index"
                            value={indexValues[winner.id] ?? winner.index ?? ''}
                            onChange={(e) => handleIndexChange(winner.id, e.target.value)}
                            onBlur={() => handleBlurOrEnter(winner.id, winner.index)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') handleBlurOrEnter(winner.id, winner.index);
                            }}
                            size="small"
                            variant="outlined"
                            style={{ width: '4rem', marginLeft: '1rem' }}
                          />
                          <Button
                            component={Link}
                            to={`/data-entry/winners/${winner.id}/update`}
                            variant="contained"
                            target='_blank'
                          >
                            update
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  </>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container >
    </Layout >
  );
};


const mCss = {
  TableBody: ({
  }),
  // '.MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter MuiTableCell-sizeMedium css-gn79ij-MuiTableCell-root': {
  '.MuiTableCell-root MuiTableCell-body': {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: 'red !important',
    backgroundColor: 'blue !important',

  }

}

export default WinnersListPage;