/** @jsxImportSource @emotion/react */
import { Button, Container, Paper, Stack, TextField } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import Layout from '../../page/Layout'

import { BgiLink } from '../../../UI/BgiLink'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { BgiSelect } from '../../../UI/BgiSelect'
import { WinnerFVT } from '../../../types/winner'
import { useCreateWinnerMutation, useDeleteWinnerMutation, useUpdateWinnerMutation } from '../../../store/winners'
import { BgiTextField } from '../../../UI/BgiTextField'
import { AssetField } from '../../gallery/AssetField'




const SUCCESS_MSGS = {
  create: (winnerId: string) => (
    <p>
      Course has been created successfully with{' '}
      <BgiLink color="#fff" to={`/data-entry/winners/${winnerId}`}>
        #{winnerId}
      </BgiLink>
    </p>
  ),
  update: 'تم تحديث الكورس بنجاح',
}


const getErrMsg = buildGetErrMsg({
  DUP_COURSE_CODE: 'كود الدورة مكرر. ادخل كود آخر',
  COURSE_IS_REFERENCED: "Please delete course's chapters first",
})


// --Comp---------------------------------------------------------------------------
// ---------------------------------------------------------------------------------
const WinnerForm = () => {
  const { control, handleSubmit, reset } = useFormContext<WinnerFVT>()
  const winnerId = useWatch({ control, name: 'id' })
  const isCreateForm = !winnerId

  const { enqueueSnackbar } = useSnackbar()


  const [updateWinner, { isLoading: isUpdating, error: updateErr }] = useUpdateWinnerMutation()
  const [createWinner, { isLoading: isCreating, error: createErr }] = useCreateWinnerMutation()
  const [deleteWinner, { isLoading: isDeleting, error: deleteErr }] = useDeleteWinnerMutation()

  const getErrMsg = buildGetErrMsg();

  // -2--------------------------------------------------------------------------------
  const onSubmit = handleSubmit(async (winner: WinnerFVT) => {
    const { id,
      competitionImageFile,
      sponsorImageFile,
      prizeImageFile,
      winnerImageFile,
      ...restPayload
    } = winner;


    const payload = {
      ...restPayload,
      index: restPayload.index ? Number(restPayload.index) : undefined,
      competitionImageFileId: competitionImageFile?.id ?? null,
      sponsorImageFileId: sponsorImageFile?.id ?? null,
      prizeImageFileId: prizeImageFile?.id ?? null,
      winnerImageFileId: winnerImageFile?.id ?? null,
    };

    try {
      const result = id
        ? await updateWinner({ id, ...payload })
        : await createWinner({ ...payload });

      if ('data' in result) {
        enqueueSnackbar({
          message: id
            ? SUCCESS_MSGS['update']
            : SUCCESS_MSGS['create'](result.data.id), variant: 'success',
        });

        if (!id) reset();
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar({ message: 'Something went wrong', variant: 'error' });
    }
  });


  // --------------------------------------------------------------------
  const onDelete = async () => {
    if (!winnerId) return;
    const isConfirmed = window.confirm(`Are you sure you to delete Unit ${winnerId}`);
    if (!isConfirmed) return;

    const result = await deleteWinner(winnerId)
    if ('data' in result) {
      enqueueSnackbar({
        message: 'The Winner has been deleted successfully',
        variant: 'success',
      })
      // not navigate because i think it's not necessary
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }


  // ---return-------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------
  return (
    <Layout>
      <div style={{ width: '100%', height: 30 }} />

      <form onSubmit={onSubmit}>
        <Stack spacing={2}>
          <BgiTextField
            name="competitionName"
            label="Competition Name"
          />
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <AssetField
              name='competitionImageFile'
              label='Competition Image'
              fileKind='image'
            />

            <AssetField
              name='winnerImageFile'
              label='Winner Image'
              fileKind='image'
            />
          </span>
          <BgiTextField
            name="winnerName"
            label="Winner Name"
          />

          <BgiTextField
            name="address"
            label="Address"
          />
          <BgiTextField
            name="index"
            label="index"
          />
          <BgiSelect
            name="status"
            label="Status"
            options={[
              { label: 'Active', value: 'Active' },
              { label: 'Inactive', value: 'Inactive' },
              { label: 'Finished', value: 'Finished' },
              { label: 'Hidden', value: 'Hidden' },
            ]}
            defaultValue='Inactive'
          />

          <BgiTextField
            name="sponsorName"
            label="Sponsor Name"
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <AssetField
              name="sponsorImageFile"
              label="Sponsor Image"
              fileKind="image"
            />
            <AssetField
              name="prizeImageFile"
              label="Prize Image"
              fileKind="image"
            />
          </div>

          <BgiTextField
            name="sponsorLink"
            label="Sponsor Link"
          />

          <BgiTextField
            name="sponsorDescription"
            label="Sponsor Description"
          />



          <BgiTextField
            name="groupNumber"
            label="Group Number"
          />

          <BgiTextField
            name="date"
            label="Date"
          />

          <BgiTextField
            name="note"
            label="Note"
          />


          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isCreating || isUpdating}
            >
              {winnerId ? 'Update' : 'Create'}
            </Button>

            <Button
              color="error"
              variant="outlined"
              onClick={onDelete}
              disabled={isDeleting}>
              Delete
            </Button>
          </div>

        </Stack>
      </form>

    </Layout>
  )
}

export default WinnerForm


















// const onSubmit = async (winner: WinnerFVT) => {
//   const { id, ...restPayload } = winner

//   const result = (
//   ? await updateWinner({ id, ...restPayload })
//     : await createWinner(restPayload)
//     )
//   if ('data' in result) {
//     enqueueSnackbar(SUCCESS_MSGS[id ? 'update' : 'create'],
//       { variant: (SUCCESS_MSGS['update'] ? (variant: 'success') : (variant: 'error') ) })
//     reset()
//   } else {
//     enqueueSnackbar(getErrMsg(error?.code), { variant: 'error' })
//   }
// }
// return (
//   <div>

//   </div>
// )
// }
