// import { display } from "html2canvas/dist/types/css/property-descriptors/display"

export interface mainTitlesT{
  id: string,
  title: string
}

export interface QuickAccessCoursesDataT{
  mainTitleId: string,
  title: string,
  description: string,
  disabled: boolean
}

export const mainTitles: (mainTitlesT | string)[] = [
  {id: '300', title: 'الفيزياء'},
  {id: '400', title: 'الأحياء وعلوم الأرض'},
  {id: '500', title: ' العلوم'},
  {id: '600', title: 'اللغة الإنجليزية'},
]

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
export const QuickAccessCoursesData: (QuickAccessCoursesDataT | string)[] = [
  {mainTitleId: '300',
    title: 'الفيزياء للصف الثالث الثانوي', 
    description: 'https://www.bgi-edu.com/subcollections/1/courses', 
    disabled: false
  },
  {mainTitleId: '300',
    title: 'الفيزياء للصف الثاني الثانوي', 
    description: 'https://www.bgi-edu.com/subcollections/3/courses', 
    disabled: false
  },
  {mainTitleId: '300',
    title: 'الفيزياء للصف الأول الثانوي', 
    description: 'https://www.bgi-edu.com/subcollections/5/courses', 
    disabled: false
  },
  {mainTitleId: '400',
    title: 'علم الأحياء وعلم الأرض للصف الثالث الثانوي', 
    description: 'https://www.bgi-edu.com/subcollections/15/courses', 
    disabled: false
  },
  {mainTitleId: '500',
    title: 'العلوم للصف التاسع', 
    description: 'https://www.bgi-edu.com/subcollections/10/courses', 
    disabled: false
  },
  {mainTitleId: '600',
    title: 'اللغة الإنجليزية للصف الثالث الثانوي', 
    description: 'https://www.bgi-edu.com/subcollections/13/courses', 
    disabled: false
  },
  {mainTitleId: '600',
    title: 'اللغة الإنجليزية للصف التاسع - تحت  الإنجاز', 
    description: 'https://www.bgi-edu.com/subcollections/14/courses', 
    disabled: false
  },

]