/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import {
  useGetAllCompetitionScheduleQuery,
  useUpdateCompetitionScheduleMutation,
} from '../../../store/competitionsScheduleApi'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardMedia,
  Container,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { css } from '@emotion/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Layout from '../../page/Layout'
import { AccessTimeFilled, AdsClick, LeakAdd } from '@mui/icons-material'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import MarkdownView from '../../slides/slide-view/MarkdownView'
import makeStyles from '../../../utils/makeStyles'
import { useAppSelector } from '../../../store/hooks'
import EventIcon from '@mui/icons-material/Event'
import CompetitionScheduleCard from './CompetitionScheduleCard'

// ---Comp---------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------
const CompetitionListPage = () => {
  const { data: competitions } = useGetAllCompetitionScheduleQuery()
  const [UpdateCompetitionSchedule] = useUpdateCompetitionScheduleMutation()

  const handleUpdateIndex = async (id: string, newIndex: number) => {
    try {
      await UpdateCompetitionSchedule({ id, index: Number(newIndex) })
    } catch (error) {
      console.error('Failed to update index:', error)
    }
  }

  const [indexValues, setIndexValues] = useState<{ [key: string]: number | undefined }>({})

  if (competitions?.length === 0) {
    return <p>There are no competitions yet</p>
  }
  // ---status ActiveInactiveFin-------------------------------------------------------------------

  // -----------------------------------------------------------------status ActiveInactiveFin-----

  const sortedCompetitions = competitions ? [...competitions].sort((a, b) => (b.index ?? 0) - (a.index ?? 0)) : []


  // ---return-------------------------------------------------------------------
  // ---......-------------------------------------------------------------------
  return (
    <Layout>
      <Container maxWidth="md" dir="rtl">
        <div style={{ width: '100%', height: 30 }} />
        <h2 style={{ textAlign: 'center' }}>مواعيد مسابقات منصة فوتون العلمية</h2>
        <div style={{ width: '100%', height: 30 }} />

        {sortedCompetitions?.map((competition, idx) => {
          const handleIndexChange = (id: string, value: string) => {
            setIndexValues((prev) => ({
              ...prev,
              [id]: Number(value),
            }))
          }

          const handleBlurOrEnter = (id: string, currentIndex: number | undefined) => {
            if (indexValues[id] !== currentIndex) {
              handleUpdateIndex(id, indexValues[id] ?? 0)
            }
          }

          return (
            <CompetitionScheduleCard
              key={competition.id}
              idx={idx}
              competition={competition}
              indexValues={indexValues}
              handleIndexChange={handleIndexChange}
              handleBlurOrEnter={handleBlurOrEnter}
            />
          )
        })}
      </Container>
    </Layout>
  )
}

export default CompetitionListPage
