/** @jsxImportSource @emotion/react */

import Layout from './Layout';
import {
  mainTitles,
  QuickAccessCoursesData,
  QuickAccessCoursesDataT,
} from '../../data/QuickAccessCoursesData';
import { Container, Paper } from '@mui/material';
import AccordionPage from '../../UI/AccordionPage';
import { css } from '@emotion/react'



const QuickAccessCoursesPage = () => {
  return (
    <Layout>
      <br /><br /><br />

      <Container>
        {/* TODO: active the div when i add new cousre  */}
        {/* <div css={mcss.courseCss}> */}
        {mainTitles.map((mainTitle, idx) => {
          if (typeof mainTitle === 'object' && 'id' in mainTitle) {
            const filteredCourses = QuickAccessCoursesData.filter(
              (course) => typeof course === 'object' && course.mainTitleId === mainTitle.id
            ) as QuickAccessCoursesDataT[];
            return (
              <Paper elevation={12} key={idx} >
                <h3 css={mcss.headingCss}>{mainTitle.title}</h3>
                {filteredCourses.length > 0 && (
                  <AccordionPage
                    allData={filteredCourses}
                    color="info"
                  />
                )}
                <br />
              </Paper>
            );
          }
          return null;
        })}

        {/* </div> */}
      </Container>

    </Layout>
  )
}

const mcss = {
  courseCss: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.2rem',
    direction: 'rtl',
  }),
  headingCss: css({
    backgroundColor: '#f0f8ff',
    border: '2px solid #007bff',
    borderRadius: '8px',
    padding: '10px 15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    color: '#007bff',
    fontFamily: `'Arial', sans-serif`,
    fontSize: '3rem',
    textAlign: 'center',
    marginBottom: '20px',
  }),
};

export default QuickAccessCoursesPage
