/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { SlideView } from '../../../types/SlideView'
import { OnlyTextSlide } from '../../../types/slide'
import MarkdownView from './MarkdownView'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { css } from '@emotion/react'
import makeStyles from '../../../utils/makeStyles'
import { determineTextDirection } from '../../../utils/determineTextDirection'



const SlideViewOnlyText: SlideView<OnlyTextSlide> = ({ slide, filesHashes }) => {

  const imageUrl = useMemo(() => {
    if (!slide.imageFileId) {
      return undefined
    }
    const imageFileHash = filesHashes.find(
      (fileHash) => fileHash.id === slide.imageFileId
    )!.fileHash

    return `${FILES_BASE_URL}/${imageFileHash}`
  }, [])

  const isImg = slide.imageFileId
  const direction = determineTextDirection(slide.onlyText);


  // ----------------------------------------------------

  return (
      <div 
        css={isImg ? styles.withImg : styles.withoutImg}
        // data-exist={isImg ? 'withImg' : 'withoutImg'}
      >
        <img
        css={styles.imgWithoutCon}
          src={imageUrl}
          alt=""
        />
        <div  dir={direction}>
          <MarkdownView value={slide.onlyText} />
        </div>
      </div>
    )
    
}

export default SlideViewOnlyText


const styles = makeStyles({
  withImg: {
    display: 'grid',
    gridTemplateColumns: ' 1fr 2fr',
    width: '100%',
    '@media screen and (max-width: 676px)':{
      display: 'block',
    },
  },
  'withoutImg': {
    display: 'flex',
    flexDirection: 'column-reverse',
    margin: '0 0.5rem ', 
    // backgroundColor: 'beige',
  },
  imgWithoutCon :{
    width: '20rem', 
    margin: '1rem 1rem 0 0',
    borderRadius: '0.8rem',
    textAlign: 'center',
    '@media screen and (max-width: 676px)':{
      width: '100%',
      height: 'auto',
      display: 'flex',
      margin: 'auto',
    },
  }, 
})
