import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiConfig } from './api'
import { CompetitionScheduleT, CreateCompetitionScheduleT } from '../types/competitionsSchedule'

export const competitionsScheduleApi = createApi({
  reducerPath: 'competitionsScheduleApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/competitions-schedule`,
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getAllCompetitionSchedule: builder.query<CompetitionScheduleT[], void>({
      query: () => '',
    }),
    getCompetitionSchedule: builder.query<CompetitionScheduleT | null, string>({
      query: (competitionScheduleId) => `/${competitionScheduleId}`,
    }),
    createCompetitionSchedule: builder.mutation<CompetitionScheduleT, CreateCompetitionScheduleT>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
    }),
    updateCompetitionSchedule: builder.mutation<CompetitionScheduleT, Partial<CreateCompetitionScheduleT> & Pick<CompetitionScheduleT, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
    }),

    deleteCompetitionSchedule: builder.mutation<void, string>({
      query: (competitionScheduleId) => ({ url: `/${competitionScheduleId}`, method: 'DELETE' }),
    }),
  }),
});





export const {
  useGetAllCompetitionScheduleQuery,
  useGetCompetitionScheduleQuery,
  useCreateCompetitionScheduleMutation,
  useUpdateCompetitionScheduleMutation,
  useDeleteCompetitionScheduleMutation,
} = competitionsScheduleApi

