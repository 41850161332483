import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CreateWinnerT, WinnerFVT, WinnerT } from '../types/winner'
import { apiConfig } from './api'




export const winnersApi = createApi({
  reducerPath: 'winnersApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/winners`,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['Winner'],
  endpoints: (builder) => ({
    getAllWinners: builder.query<WinnerT[], void>({
      query: () => '',
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Winner' as const, id })), 'Winner']
          : ['Winner'],
    }),
    getWinner: builder.query<WinnerT | null, string>({
      query: (winnerId) => `/${winnerId}`,
    }),
    createWinner: builder.mutation<WinnerT, CreateWinnerT>({
      query: (body) => ({url: `/`, method: 'POST', body})
    }),
    updateWinner: builder.mutation<WinnerT, Partial<WinnerT> & Pick<WinnerT, 'id'>>({
      query: ({id, ...body}) => ({url: `/${id}`, method: 'PATCH', body}),
    }),
    deleteWinner: builder.mutation<void, string>({
      query: (WinnerId) => ({url: `/${WinnerId}`, method: 'DELETE'}),
      invalidatesTags: ['Winner']
    }),
  }),
})

export const {
  useGetAllWinnersQuery,
  useGetWinnerQuery,
  useLazyGetWinnerQuery,
  useCreateWinnerMutation,
  useUpdateWinnerMutation,
  useDeleteWinnerMutation,
} = winnersApi