import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import MathJax from 'react-mathjax';
import { CSSProperties, FC } from 'react';
import { determineTextDirection } from '../../../utils/determineTextDirection';

let rehypeRaw: any;
(async () => {
  rehypeRaw = (await import('rehype-raw')).default;
})();

interface MarkdownViewProps {
  value: string;
  style?: CSSProperties
}

const MarkdownView: FC<MarkdownViewProps> = ({ value, style }) => {
  const direction = determineTextDirection(value);

  return (
    <MathJax.Provider>
      <div dir={direction} style={style}>
        <ReactMarkdown
          remarkPlugins={[remarkMath]}
          rehypePlugins={[rehypeRaw]}
          components={{
            div({ node, ...props }) {
              return <div {...props} />;
            },
            span(spanProps) {
              const { className, children } = spanProps;
              if (
                className &&
                className.includes('math') &&
                className.includes('math-inline')
              ) {
                const value =
                  children instanceof Array
                    ? children.join(' ')
                    : String(children);
                return <MathJax.Node inline formula={value} />;
              }
              const { node, ...restProps } = spanProps;
              return <span {...restProps} />;
            },
          }}
        >
          {value}
        </ReactMarkdown>
      </div>
    </MathJax.Provider>
  );
};

export default MarkdownView;






// ## <div style="text-align: center;"><h3>سيكون من الأفضل </h3></div>








//  the old one, ist work normal but i added the new one to add the rehypeRaw
// import ReactMarkdown from 'react-markdown';
// import remarkMath from 'remark-math';
// import MathJax from 'react-mathjax';
// import { FC } from 'react';

// interface MarkdownViewProps {
//   value: string;
// }

// const MarkdownView: FC<MarkdownViewProps> = ({ value }) => {
//   // const containsMarkdown = /[#*]/.test(value)
//   // if (containsMarkdown) {
//     return (
//       <MathJax.Provider>
//         <ReactMarkdown
//           remarkPlugins={[remarkMath]}
//           components={{
//             div({node, ...props}) {
//               return <div {...props} />;
//             },
//             span(spanProps) {
//               const { className, children } = spanProps;
//               if (
//                 className &&
//                 className.includes('math') &&
//                 className.includes('math-inline')
//               ) {
//                 const value =
//                   children instanceof Array
//                     ? children.join(' ')
//                     : String(children);

//                 return <MathJax.Node inline formula={value} />;
//               }

//               const { node, ...restProps } = spanProps;

//               return <span {...restProps} />;
//             },
//           }}
//         >
//           {value}
//         </ReactMarkdown>
//       </MathJax.Provider>
//     );

//   // } else {
//   //   return <BgiTextField
//   //   value={value}
//   //   label="Question"
//   //   name="question"
//   //   fullWidth
//   //   size="small"
//   //   // style="style"
//   //   required
//   //   />
//   // }

// };

// export default MarkdownView;
