import React, { FC } from 'react';
import MarkdownView from '../../slides/slide-view/MarkdownView';
import { useFormContext, useWatch } from 'react-hook-form';
import { TextField } from '@mui/material';

const exampleContent = `
Given a **formula** below
$$
s = ut + \\frac{1}{2}at^{2}
$$
<div style="text-align: center; color: red; font-size: 24px;">
  <h3></h3>
</div>
Calculate the value of $s$ when $u = 10\\frac{m}{s}$ and $a = 2\\frac{m}{s^{2}}$ at $t = 1s$;
`;

interface MarkdownEditorProps {
  label: string;
  name: string;
  isPreview: boolean
  minRows: number
  maxRows: number
}

const MarkdownEditor: FC<MarkdownEditorProps> = ({ name, label, isPreview, minRows, maxRows }) => {
  const { setValue, control } = useFormContext();
  const value = useWatch({ control, name });

  return (
    <React.Fragment>
      <TextField
        variant="outlined"
        fullWidth
        minRows={minRows}
        maxRows={maxRows}
        label={label}
        multiline
        value={value}
        onChange={(e) => setValue(name, e.target.value)}
        size="small"
        style={{ backgroundClip: 'black' }}
        placeholder='<div style="text-align: center;"><h3>سيكون من الأفضل </h3></div>
'
      />
      {isPreview && <MarkdownView value={value} />}
    </React.Fragment>
  );
};

export default MarkdownEditor;







// import React, { FC } from 'react';
// import MarkdownView from '../../slides/slide-view/MarkdownView';
// import { useFormContext, useWatch } from 'react-hook-form';
// import { TextField } from '@mui/material';

// const exampleContent =
//   'Given a **formula** below' +
//   '$$' +
//   's = ut + \\frac{1}{2}at^{2}' +
//   '$$' +
//   '<div style="text-align: center; color: red; font-size: 24px;">' +
//   '</div>' +
//   'Calculate the value of $s$ when $u = 10\\frac{m}{s}$ and $a = 2\\frac{m}{s^{2}}$ at $t = 1s$;';

// interface MarkdownEditorProps {
//   label: string;
//   name: string;
//   isPreview: boolean
//   minRows: number
//   maxRows: number
// }

// const MarkdownEditor: FC<MarkdownEditorProps> = ({ name, label, isPreview, minRows, maxRows }) => {


//   const { setValue, control } = useFormContext();
//   const value = useWatch({ control, name });

//   return (
//     <React.Fragment>
//       <TextField
//         variant="outlined"
//         fullWidth
//         minRows={minRows}
//         maxRows={maxRows}
//         label={label}
//         multiline
//         value={value}
//         onChange={(e) => setValue(name, e.target.value)}
//         size="small"
//         style={{ backgroundClip: 'black' }}

//       />

//       {isPreview && <MarkdownView value={value} />}
//     </React.Fragment>
//   );
// };

// export default MarkdownEditor;