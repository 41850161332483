import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CompetitionScheduleForm } from './CompetitionScheduleForm'
import { CompetitionScheduleFV } from '../../../types/competitionsSchedule'
import { Container } from '@mui/material'

const CreateCompetitionSchedule = () => {
  const formContext = useForm<CompetitionScheduleFV>({
    defaultValues: {
      startDate: 'YYYY-MM-DD',
      endDate: 'YYYY-MM-DD',
      duration: 'مدة ساعتين',
      description: `## 
---
#### 
#### 
      `,
      competitionRules: `## قوانين المسابقة
---
#### 
#### 
`,
      sponsorDescription: `## 
---
#### 
#### 
`,
      prizeType: `## 
---
####
####
      ` ,
      prizeDescription: `## 
---
####
####
      `,
    }
  })

  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <CompetitionScheduleForm />
      </FormProvider>
    </Container>
  )
}

export default CreateCompetitionSchedule
