/** @jsxImportSource @emotion/react */
import { FC, useState } from 'react';
import { useGetAllCompetitionScheduleQuery, useUpdateCompetitionScheduleMutation } from '../../../store/competitionsScheduleApi'
import { Accordion, AccordionDetails, AccordionSummary, Button, CardMedia, Container, Paper, TextField, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { css } from '@emotion/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Layout from '../../page/Layout'
import { AccessTimeFilled, AdsClick, LeakAdd } from '@mui/icons-material'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MarkdownView from '../../slides/slide-view/MarkdownView'
import makeStyles from '../../../utils/makeStyles'
import { useAppSelector } from '../../../store/hooks'
import EventIcon from '@mui/icons-material/Event';
import { CompetitionScheduleT } from '../../../types/competitionsSchedule';

interface CompetitionScheduleCardProps {
  competition: CompetitionScheduleT
  handleIndexChange?: (id: string, value: string) => void
  handleBlurOrEnter?: (id: string, currentIndex: number | undefined) => void
  indexValues?: { [key: string]: number | undefined }
  idx?: number
}


// ---Comp---------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------
const CompetitionScheduleCard: FC<CompetitionScheduleCardProps> = ({
  competition,
  handleIndexChange,
  handleBlurOrEnter,
  indexValues,
  idx = 0
}) => {
  const userMe = useAppSelector((state) => state.auth.userMe)


  const getCompetitionStyle = (status: string) => {
    switch (status) {
      case "Active":
        return { backgroundColor: "white", display: true };
      case "Inactive":
        return { backgroundColor: "beige", display: true };
      case "Finished":
        return { backgroundColor: "grey", display: true };
      case "Hidden":
        return { backgroundColor: "", display: false };
      default:
        return { backgroundColor: "white", display: false };
    }
  };

  // ----------------------------------------------------------------------------------------------
  const renderCardMedia = (isImageFile: { fileHash: string } | undefined, width = '100%', height = '100%') => {
    return isImageFile ? (
      <CardMedia
        component="img"
        style={{
          width,
          height,
          borderRadius: 12,
          objectFit: 'contain',
        }}
        image={`${FILES_BASE_URL}/${isImageFile.fileHash}`}
      />
    ) : null;
  };

  const { display, backgroundColor } = getCompetitionStyle(competition.status)


  if (!display) return null;




  // ---return-------------------------------------------------------------------
  // ---......-------------------------------------------------------------------
  return (
    <Accordion key={competition.id} style={{ backgroundColor }}
      sx={{

        '& span': {
          display: 'grid',
          gridTemplateColumns: '1fr 2fr 1fr',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          '& h3': {
            fontSize: '1.2rem',
            margin: 'auto',
            textAlign: 'center',
          },
          '@media only screen and (max-width: 760px)': {
            '& h3': {
              fontSize: '0.8rem',
              margin: 'auto',
              textAlign: 'center',
            },
          }

        }
      }}>

      <Paper elevation={12}>
        <AccordionSummary
          style={{ backgroundColor }}
          aria-controls={`panel${idx}-content`}
          id={`panel${idx}-header`}
        >
          <span>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> {renderCardMedia(competition.competitionImageFile, '7rem', '7rem')}</div>
            <label><h3>{competition.title}</h3></label>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{renderCardMedia(competition.sponsorImageFile, '7rem', '7rem')}</div>

            <div><ExpandMoreIcon style={{ margin: 0, padding: 0, justifyContent: 'center' }} /><label>التفاصيل</label></div>
            <label>{competition.status === "Inactive" ? <h3>المنافسة  ستبدأ قريباً</h3> : <h3>انتهت المسابقة</h3>}</label>
          </span>
        </AccordionSummary>

        <div>
          {userMe?.role === 'admin' && indexValues && handleIndexChange && handleBlurOrEnter && (
            <>
              <Button
                style={{ margin: ' 0 1rem' }}
                component={Link}
                to={`/data-entry/competition-schedule/${competition.id}/update`}
                variant="contained"
                target='_blank'
              >
                Update
              </Button>

              <TextField
                label="index"
                value={indexValues[competition.id] ?? competition.index ?? ''}
                onChange={(e) => handleIndexChange(competition.id, e.target.value)}
                onBlur={() => handleBlurOrEnter(competition.id, competition.index)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleBlurOrEnter(competition.id, competition.index);
                }}
                size="small"
                variant="outlined"
                style={{ width: '4rem', marginLeft: '1rem' }}
              />
              <span>{competition.index}</span>
            </>
          )
          }


        </div>
      </Paper>
      {/* ---------------------------------------------------------------------- */}
      <AccordionDetails>
        <Paper elevation={12}>
          <div css={mCss.compDescription}>
            <div>
              <MarkdownView value={competition.description ?? ''} />
              <div css={mCss.appointment}>
                <h2 css={mCss.dateCss}><EventIcon /> {competition.startDate}</h2>
                <h2 css={mCss.durationCss}> <AccessTimeFilled /> {competition.duration}</h2>
              </div>
              <span css={mCss.competitionLinkCss}>
                {competition.competitionLink &&
                  <Button
                    component={Link} variant='contained' color='primary' target="_blank"
                    to={competition.competitionLink}>رابط المسابقة <AdsClick />
                  </Button>
                }

                {competition.linkToPractice &&
                  <Button
                    component={Link} variant='contained' color='primary' target="_blank"
                    to={competition.linkToPractice}>رابط للتدريب   <FitnessCenterIcon />
                  </Button>
                }
              </span>

            </div>

            <div>
              <MarkdownView value={competition.competitionRules ?? ''}
                style={{ justifyContent: "space-between", background: "linear-gradient(#f5f5dc 0%, #e8e4c9 90%, #dcd8b2 100%)", }}
              />
            </div>
          </div>
        </Paper>
        {/* ---------------------------------------------------------------------- */}
        <Paper elevation={24}>
          <div css={mCss.competitionCss}>
            <div style={{ display: 'block' }}>
              <MarkdownView value={competition.sponsorTitle ?? ''} />
              <MarkdownView value={competition.sponsorDescription ?? ''} />
              {competition.sponsorLink &&
                <Button
                  component={Link} variant='contained' color='secondary' target="_blank" size="large"
                  to={competition.sponsorLink}>  <AdsClick /> <LeakAdd />
                </Button>
              }
            </div>
            <div css={mCss.prizeCss}>
              <MarkdownView value={competition.prizeType ?? ''} />
              {renderCardMedia(competition.prizeImageFile, '70%', '80%')}
              <MarkdownView value={competition.prizeDescription ?? ''} />
            </div>
          </div>

        </Paper>
      </AccordionDetails>
    </Accordion>
  )
}


const mCss = makeStyles({

  dateCss: css({
    fontSize: '1.5rem',
    border: '2px solid #ccc',
    borderRadius: '0.5rem',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    transition: 'box-shadow 0.3s ease',
    textAlign: 'center',
    padding: '0.7rem',
    backgroundColor: '#1976d2',
    color: 'whitesmoke',

    '@media only screen and (max-width: 760px)': {
      'css-145e7u6-CompetitionScheduleCard': {
        display: 'block',
        width: '100%',
        fontSize: '1rem',
        colorBackground: 'green',
      }
    },
    ':hover': {
      boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
      borderColor: '#ffcc99',
    }
  }),
  durationCss: css({
    fontSize: '1rem',
    border: '2px solid #ccc',
    borderRadius: '0.5rem',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    transition: 'box-shadow 0.3s ease',
    textAlign: 'center',
    padding: '0.7rem',
    backgroundColor: '#1976d2',
    color: 'whitesmoke',
    '@media only screen and (max-width: 760px)': {
      display: 'block',
      width: '100%',
      fontSize: '0.8rem',
    },
  }),
  appointment: css({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '0.5rem',
    margin: '0.5rem 1rem',

    '@media only screen and (max-width: 760px)': {
      display: 'grid',
      gridTemplateColumns: '1fr',
    },
  }),
  competitionLinkCss: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 10,
  }),


  compDescription: css({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: 10,
    padding: '1rem',
    '@media only screen and (max-width: 760px)': {
      display: 'block',
    },
    '& div': {
      padding: '1rem',
      // margin: 'auto',
      borderRadius: '0.5rem',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      transition: 'box-shadow 0.3s ease',
      ':hover': {
        boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
      }
    }
  }),

  competitionCss: css({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    margin: 'auto 2rem',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    '@media only screen and (max-width: 760px)': {
      display: 'block',
      width: '100%',
      margin: '1rem 0.5rem',
    },
    '& h3': {
      fontSize: '1rem',
      margin: 'auto',
    }
  }),

  prizeCss: css({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  }),

})

export default CompetitionScheduleCard
