/** @jsxImportSource @emotion/react */
import { memo, useEffect, useMemo, useState } from 'react'

import { Button, Container, Paper, Stack, TextField } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useCreateCompetitionScheduleMutation, useDeleteCompetitionScheduleMutation, useUpdateCompetitionScheduleMutation } from '../../../store/competitionsScheduleApi'
import UseDynamicLanguageStyle from '../../i18n/UseDynamicLanguageStyle'
import Layout from '../../page/Layout'

import { BgiTextField } from '../../../UI/BgiTextField'
import { CompetitionScheduleT, CreateCompetitionScheduleT, CompetitionScheduleFV } from '../../../types/competitionsSchedule'
import { BgiLink } from '../../../UI/BgiLink'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { AssetField } from '../../gallery/AssetField'
import { FileData } from '../../gallery/FileForm'
import { BgiCheckbox } from '../../../UI/BgiCheckbox'
import { BgiSelect } from '../../../UI/BgiSelect'
import { css } from '@emotion/react'



// ---outComponent-----------------------------------------------
const SUCCESS_MSGS = {
  create: (competitionScheduleId: string) => (
    <p>
      CompetitionSchedule has been created successfully with{' '}
      <BgiLink color="#fff" to={`/data-entry/competition-schedule/${competitionScheduleId}/create`}>
        #{competitionScheduleId}
      </BgiLink>
    </p>
  ),
  update: 'تم إضافة منافسة إلى الجدول  بنجاح',
}



const getErrMsg = buildGetErrMsg({
  DUP_COURSE_CODE: 'لم يتم إدراج اسم المنافسة بنجاح',
  COURSE_IS_REFERENCED: "Please delete course's chapters first",
})

// --Comp---------------------------------------------------------------------------
// ---------------------------------------------------------------------------------

export const CompetitionScheduleForm = memo(() => {
  const { control, handleSubmit, reset } = useFormContext<CompetitionScheduleFV>()

  const competitionScheduleId = useWatch({ control, name: 'id' })
  const isCreateForm = !competitionScheduleId

  const { enqueueSnackbar } = useSnackbar()

  const [createCompetitionSchedule, { isLoading: isCreating }] = useCreateCompetitionScheduleMutation()
  const [updateCompetitionSchedule, { isLoading: isUpdating }] = useUpdateCompetitionScheduleMutation()
  const [deleteCompetitionSchedule, { isLoading: isDeleting }] = useDeleteCompetitionScheduleMutation()


  // -2--------------------------------------------------------------------------------
  const onSubmit = async (competitionSchedule: CompetitionScheduleFV) => {
    const { id,
      competitionImageFile,
      sponsorImageFile,
      prizeImageFile,
      ...restPayload
    } = competitionSchedule


    const payload = {
      ...restPayload,
      index: restPayload.index ? Number(restPayload.index) : undefined,
      competitionImageFileId: competitionImageFile?.id ?? null,
      sponsorImageFileId: sponsorImageFile?.id ?? null,
      prizeImageFileId: prizeImageFile?.id ?? null,
    }


    if (!id) {
      const result = await createCompetitionSchedule({ ...payload })

      if ('data' in result) {

        enqueueSnackbar({
          message: SUCCESS_MSGS['create'](result.data.id),
          variant: 'success',
        })
        reset()
      } else {
        enqueueSnackbar({
          message: getErrMsg(result.error),
          variant: 'error',
        })
      }

    } else {
      const result = await updateCompetitionSchedule({ id, ...payload })
      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['update'],
          variant: 'success',
        })
      } else {
        enqueueSnackbar({
          message: getErrMsg(result.error),
          variant: 'error',
        })
      }
    }
  }
  // -3-------------------------------------------------------------------------------2-
  const onDelete = async () => {
    if (!competitionScheduleId) return
    const confirmationMsg = 'Are you sure you want to delete this row of the table?'
    if (!window.confirm(confirmationMsg)) return

    const result = await deleteCompetitionSchedule(competitionScheduleId)

    if ('data' in result) {
      enqueueSnackbar({
        message: 'The competition has been deleted successfully',
        variant: 'success',
      })
      // not navigate because i think it's not necessary
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  }

  // ---return-------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------
  return (
    <Layout>
      <div>
        <BgiLink to="/data-entry/competition-schedule">Back to Competitions Table</BgiLink>
        <div style={{ width: '100%', height: 30 }} />
        <h2>{isCreateForm ? 'Create' : 'Update'} Competition</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <BgiTextField
              name="competitionCode"
              label="Competition Code"
              multiline
              maxRows={4}
            />
            <BgiTextField
              name="title"
              label="Title"
              multiline
              maxRows={4}
            />
            <AssetField
              name="competitionImageFile"
              label="Competition Image"
              fileKind='image'
            />
            <BgiTextField
              name="competitionLink"
              label="Competition Link"
              fullWidth
            />
            <BgiTextField
              name="linkToPractice"
              label="Link to practice"
              fullWidth
            />
            <BgiTextField
              name="startDate"
              label="Start Date"
              size='small'
              placeholder='yyyy-mm-dd'
              pattern={/^(\d{4})-(\d{2})-(\d{2})$/}
            />
            <BgiTextField
              name="endDate"
              label="End Date"
              size='small'
              placeholder='yyyy-mm-dd'
              pattern={/^(\d{4})-(\d{2})-(\d{2})$/}
            />

            <BgiTextField
              name="duration"
              label="Duration"
            />

            <BgiTextField
              name="description"
              label="Description"
              multiline
              minRows={4}
            />
            <BgiTextField
              name="competitionRules"
              label="Competition Rules"
              multiline
              minRows={4}
            />
            <BgiSelect
              name="status"
              label="Status"
              options={[
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' },
                { label: 'Finished', value: 'Finished' },
                { label: 'Hidden', value: 'Hidden' },
              ]}
            />
            <BgiTextField
              name="index"
              label="index"
            />

            <BgiTextField
              name="sponsorTitle"
              label="Sponsor Title"
              multiline
              minRows={4}
            />
            <AssetField
              name="sponsorImageFile"
              label="Sponsor Image"
              fileKind='image'
            />

            <BgiTextField
              name="sponsorLink"
              label="Sponsor Link"
            />
            <BgiTextField
              name="sponsorDescription"
              label="Sponsor Description"
              multiline
              maxRows={4}
            />
            <BgiTextField
              name="prizeType"
              label="Prizes Type"
              multiline
              minRows={2}
            />
            <AssetField
              name="prizeImageFile"
              label="Prize Image"
              fileKind='image'
            />
            <BgiTextField
              name="prizeDescription"
              label="Prizes Description"
              multiline
              maxRows={4}
            />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isCreating || isUpdating}
                size="medium"
              >
                {isCreateForm ? 'Create' : 'Update'}
              </Button>

              <Button
                color="error"
                variant="outlined"
                onClick={onDelete}
                disabled={isDeleting}>
                Delete
              </Button>
            </div>
          </Stack>
        </form>
      </div>
    </Layout >
  )
}
)

// export default CompetitionScheduleForm
