import { FormProvider, useForm } from 'react-hook-form'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useGetCompetitionScheduleQuery } from '../../../store/competitionsScheduleApi'
import { CompetitionScheduleT } from '../../../types/competitionsSchedule'
import { CompetitionScheduleForm } from './CompetitionScheduleForm'
import LoadingPage from '../../LoadingPage'

const UpdateCompetitionSchedule = () => {
  const { competitionScheduleId } = useParams<{ competitionScheduleId: string }>()
  


  if (!competitionScheduleId) {
    throw new Error('Competition Schedule ID not provided.')
  }

  const formContext = useForm<CompetitionScheduleT>()
  const { data: competitionSchedule } = useGetCompetitionScheduleQuery(competitionScheduleId)
  
  if (competitionSchedule) {
    Object.keys(competitionSchedule).forEach((untypedCompetitionScheduleKey) => {
      const competitionScheduleKey = untypedCompetitionScheduleKey as keyof CompetitionScheduleT
      formContext.setValue(competitionScheduleKey, competitionSchedule[competitionScheduleKey])
    })
  }

  
  return (
    <Container maxWidth="md">
      <FormProvider {...formContext}>
        <CompetitionScheduleForm /> 
      </FormProvider>
    </Container>
  )
}

export default UpdateCompetitionSchedule
