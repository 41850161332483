import { FC } from 'react'
import { AsyncSelectSearchRhf } from '../UI/AsyncSelectSearchRhf'
import { TextField } from '@mui/material'
import { CompetitionScheduleT } from '../types/competitionsSchedule'

interface CompetitionsScheduleFormProps {
  name: string
  required?: boolean
}

export const CompetitionsScheduleForm: FC<CompetitionsScheduleFormProps> = ({ name, required }) => {
  return (
    <AsyncSelectSearchRhf
      name={name}
      requestPathname="/competitions-schedule"
      mainInputParam="search"
      noOptionsMessage="No competitions match the search"
      processOption={(competitionSchedule: CompetitionScheduleT) => ({
        label: `${competitionSchedule.id} - ${competitionSchedule.title} ${competitionSchedule.competitionCode}`,
        value: competitionSchedule.id,
      })}
      required={required}
      placeholder="Competition Schedule"
      retainUnmatchedValueWhenClose
    />
  )

}
