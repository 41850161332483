/** @jsxImportSource @emotion/react */

import { Container, Button, Paper } from '@mui/material';
import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Unit } from '../../../types/unit';
import {
  useCreateUnitMutation,
  useDeleteUnitMutation,
  useUpdateUnitMutation,
} from '../../../store/unitsApi';
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg';
import { BgiTextField } from '../../../UI/BgiTextField';
import { BgiLink } from '../../../UI/BgiLink';
import { useSnackbar } from 'notistack';
import Layout from '../../page/Layout'
import UseDynamicLanguageStyle from '../../i18n/UseDynamicLanguageStyle'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'

interface UnitFormProps { }

export interface UnitForm {
  unit: Omit<Unit, 'id' | 'slidesCount'> & { id?: string };
}



// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const UnitFormPage: FC<UnitFormProps> = () => {
  const { register, control, handleSubmit, reset } = useFormContext<UnitForm>();
  const unitId = useWatch({ control: control, name: 'unit.id' });
  const { t } = useTranslation(['Units', 'common'])

  const navigate = useNavigate();

  const [createUnit, { isLoading: isCreating, error: updateErr }] =
    useCreateUnitMutation();

  const [updateUnit, { isLoading: isUpdating, error: createErr }] =
    useUpdateUnitMutation();

  const [deleteUnit, { isLoading: isDeleting, error: deleteErr }] =
    useDeleteUnitMutation();

  const { enqueueSnackbar } = useSnackbar()



  // ---Message-----------------------------------------------------------------------
  // --------------------------------------------------------------------------
  const SUCCESS_MSGS = {
    create: (unitId: string) => (
      <p>
        {t('Unit_has_been_created_successfully_with')}{' '}
        <BgiLink color="#fff" to={`/data-entry/units/${unitId}/update`}>
          #{unitId}
        </BgiLink>
      </p>
    ),
    update: t('The_unit_has_been_updated_successfully'),
  };

  const getErrMsg = buildGetErrMsg();
  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------
  const onSubmit = handleSubmit(async ({ unit }) => {
    const { id, ...payload } = unit;

    if (id) {
      const result = await updateUnit({ id, ...payload });
      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['update'],
          variant: 'success',
        });
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
        console.log('getErrMsg', result.error);
        
      }
    } else {
      const result = await createUnit(payload);
      if ('data' in result) {
        enqueueSnackbar({
          message: SUCCESS_MSGS['create'](result.data.id),
          variant: 'success'
        });
        reset();
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
        console.log('getErrMsg_Create', result);

      }
    }
  });

  const onDelete = async () => {
    if (!unitId) return;

    const isConfirmed = window.confirm(
      `Are you sure you to delete Unit ${unitId}`
    );

    if (!isConfirmed) return;

    const result = await deleteUnit(unitId);

    if ('data' in result) {
      enqueueSnackbar({
        message: t('Unit_has_been_deleted_successfully'),
        variant: 'success'
      });
      navigate('/data-entry/units');
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }
  };

  // ---return-----------------------------------------------------------------------
  return (
    <Layout>
      <BgiLink to="/data-entry/units">{t('Back_to_units_page')}</BgiLink>

      <div style={{ width: '100%', height: 30 }} />

      <Paper style={{ padding: '1rem' }}>
        <h2 style={{ marginBottom: 16 }}>
          {unitId ? t('common:Update') : t('common:Create')} {t('Unit')}
        </h2>

        <form onSubmit={onSubmit}>
          <div css={styles.grid} style={{ marginBottom: 24 }}>
            <BgiTextField
              style={{ backgroundColor: 'beige' }}
              label={t('Unit_number')}
              name="unit.unitNum"
              required
              maxLength={64}
            />

            <BgiTextField
              style={{ backgroundColor: 'beige' }}
              label={t('Search_tags')}
              name="unit.searchTags"
              required
              maxLength={255}
            />
          </div>

          <BgiTextField
            style={{ marginBottom: 24 }}
            label={t('Brief_title')}
            name="unit.briefTitle"
            required
            maxLength={64}
          />

          <BgiTextField
            style={{ marginBottom: 24, backgroundColor: 'beige' }}
            label={t('Detailed_title')}
            name="unit.detailedTitle"
            required
            maxLength={255}
          />

          <BgiTextField
            style={{ marginBottom: 24 }}
            label={t('Summary')}
            name="unit.summary"
            required
            multiline
            minRows={2}
            maxRows={4}
          />

          <BgiTextField
            style={{ marginBottom: 24 }}
            label={t('Note')}
            name="unit.note"
            multiline
            maxLength={5000}
            minRows={2}
            maxRows={4}
          />
          <br />

          <div css={styles.actions}>
            <Button
              type="submit"
              variant="contained"
              disabled={isCreating || isUpdating}
            >
              {unitId ? t('common:Update') : t('common:Create')}
            </Button>

            {unitId && (
              <Button
                variant="outlined"
                color="error"
                onClick={onDelete}
                disabled={isDeleting}
              >
                {t('common:Delete')}{' '}{t('Unit')}
              </Button>
            )}
          </div>
        </form>
      </Paper>

      <div style={{ width: '100%', height: 30 }} />
    </Layout>
  );
};

export default UnitFormPage;

const styles = {
  short: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  summary: css({
    // height: '7rem'
  }),
  grid: css({
    display: 'grid',
    gap: 16,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  }),
  actions: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    justifyContent: 'space-between',
  }),
};
