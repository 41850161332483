/** @jsxImportSource @emotion/react */

import React, { useState } from 'react'
import { Container } from '@mui/material'
import { css } from '@emotion/react'
import Layout from '../page/Layout';
import { useTranslation } from 'react-i18next';
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle';
import { CompetitionScheduleT } from '../../types/competitionsSchedule';
import CompetitionScheduleCard from '../data-entry/competitionSchedule/CompetitionScheduleCard';

interface BeforeExamProps {
  startTime: string
  competitionSchedule?: CompetitionScheduleT
}



const BeforeExam = ({ startTime, competitionSchedule }: BeforeExamProps) => {
  const [isActive, setIsActive] = useState(true);

  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()
  const { t } = useTranslation(['Exams']);

  const [time, setTime] = useState(0);


  let days, hours, minutes, seconds;
  const now = new Date().getTime();

  let bol_beforeTest = now < new Date(startTime).getTime()

  if (isActive) {

    setIsActive(false);
    if (bol_beforeTest) {


      const countDownDate = new Date(startTime).getTime();
      let diffT = countDownDate - now
      if (diffT > 0) {
        setTime((time) => diffT);
        const interval = setInterval(function () {

          const now = new Date().getTime();
          const countDownDate = new Date(startTime).getTime();
          let diffT = countDownDate - now
          if (diffT > 0)
            setTime((time) => diffT);
          else {
            clearInterval(interval)
            window.location.reload();
          }
        }, 1000);
      }
    }

  }

  return (
    <div css={dynamicLanguageStyle}>
      <Layout>
        <Container maxWidth="sm">
          <div css={mcss.container}>

            {/* <h2>Dear student, the exam starts after</h2> */}
            <h2 style={{ direction: 'rtl' }}>{t('Dear_students,_the_competition_begins_after')}</h2>
            <div css={mcss.counter}>

              <div css={mcss.counter} >
                <span id='days'>{Math.floor(time / (1000 * 60 * 60 * 24))}</span>
                <p>Days</p>
              </div>

              <div css={mcss.counter}>
                <span id='hours'>{Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))}</span>
                <p>Hours</p>
              </div>

              <div css={mcss.counter}>
                <span id='minutes'>{Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))}</span>
                <p>Minutes</p>
              </div>

              <div css={mcss.counter}>
                <span id='seconds'>{Math.floor((time % (1000 * 60)) / 1000)}</span>
                <p>Seconds</p>
              </div>
            </div>
          </div>

          <div css={mcss.logo}>
            <img src="/bgiIcon512.jpg" alt="Logo" />
          </div>



          {competitionSchedule && (
            <CompetitionScheduleCard competition={competitionSchedule} />
          )}
        </Container>
      </Layout>
    </div>
  )
}



const mcss = {
  container: css({
    textAlign: 'center',
    verticalAlign: 'middle',
    marginTop: '20%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '25rem',
  }),

  counters: css({
    margin: '20px',
  }),
  counter: css({
    background: '#0288D1',
    color: '#FFFFFF',
    display: 'inline-block',
    minWidth: '100px',
    '& > p': {
      background: '#0069ff',
      padding: '8px',
    },
  }),
  span: css({
    display: 'block',
    height: '125px',
    fontSize: '50px',
  }),

  mediaQueries: css({
    '@media (min-width: 768px)': {
      '#buttonStartTest': {
        fontSize: '24px',
        minWidth: '196px',
      },
    },
  }),
  logo: css({
    display: 'flex',
    height: '15rem',
  }),
};





export default BeforeExam
