import { FormProvider, useForm } from 'react-hook-form';
import { CreateWinnerT } from '../../../types/winner';
import { Container } from '@mui/material';
import WinnerForm from './WinnerForm';



const CreateWinner = () => {
  const formContext = useForm<CreateWinnerT>({
    defaultValues: {
    status: 'Active',
    sponsorDescription: '---',
    }
  })
  return (
    <Container>
      <FormProvider {...formContext}>
        <WinnerForm />
      </FormProvider>
    </Container>

  )
}

export default CreateWinner
